<template>
    <div class="phi-calendar">
        <div
            v-for="(month, mk) in months"
            :key="mk"
            class="phi-calendar-month"
        >
            <table
                cellspacing="0"
                cellpadding="0"
            >
                <thead>
                    <tr>
                        <td v-for="day in month.weeks[0].days" :key="day.timestamp">
                            {{ $date(day.timestamp, "EEE") }}
                        </td>
                    </tr>
                </thead>

                <tbody>
                    <tr
                        v-for="(week, k) in month.weeks"
                        :key="k"
                    >
                        <td
                            v-for="(day, dk) in week.days"
                            :key="dk"
                            @click="clickDay(day)"
                            class="phi-calendar-day"
                            :class="[day.classnames, {selected: isSelected(day), first: k == 0 && dk == 0}]"
                        >
                            <div>
                                <span class="date">
                                    <span class="month">{{ $date(day.timestamp, "MMM") }}</span>
                                    <span class="day">{{ day.date.getDate() }}</span>
                                </span>

                                <ul class="tags">
                                    <li
                                        v-for="tag in getTags(day)"
                                        :key="tag.label"
                                        :style="{'background-color': tag.color}"
                                    ></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "PhiCalendar",

    props: {
        value: {
            type: Date,
            required: false,
            default: new Date
        },

        startDay: {
            type: Number,
            required: false,
            default: 1
        },

        events: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    data() {
        return {
            months: [],
            focusedMonthIndex: null
        }
    },

    watch: {
        value(newVal) {
            // Si la nueva fecha seleccionada no esta en el mes mostrado,
            // recargar alrededor de la nueva fecha
            if (this.focusedMonthIndex) {
                let focusedDate = this.months[this.focusedMonthIndex].weeks[1].days[0].date;
                if (newVal.getMonth() != focusedDate.getMonth() || newVal.getFullYear() != focusedDate.getFullYear()) {
                    this.reset(newVal);
                }
            }
        }
    },

    methods: {
        clickDay(day) {
            this.$emit("input", day.date);
        },

        getTags(day) {
            let events = this.$store.getters.events(day.date);
            if (!events.length) {
                return [];
            }

            let labels = {};
            events.forEach(event => {
                let label = typeof(event.post) != "undefined" ? event.post.type.singular : event.postEvent.post.type;
                let color = typeof(event.post) != "undefined" ? event.post.type.color : 'cadetblue';

                if (typeof labels[label] == "undefined") {
                    labels[label] = {
                        label,
                        color,
                        count: 0
                    };
                }
                labels[label].count++;
            });

            let retval = [];
            for (let k in labels) {
                retval.push(labels[k]);
            }
            return retval;
        },

        isSelected(day) {
            return day.date.getFullYear() === this.value.getFullYear() &&
                   day.date.getMonth()    === this.value.getMonth() &&
                   day.date.getDate()     === this.value.getDate();
        },

        getWeeks(date, startDay = 1, nWeeks = 6) {
            let retval = [];
            let startDate = new Date(date.getFullYear(), date.getMonth(), 1);

            // Mover startDate al dia "startDay" mas cercano
            let offset = startDate.getDay() - startDay;
            if (offset < 0) {
                offset = 7 + offset;
            }
            startDate.setDate( startDate.getDate() - offset );

            let curDate = startDate;
            for (let week = 1; week <= nWeeks; week++) {
                let objWeek = {
                    days: []
                };

                for (let dayN = 0; dayN <= 6; dayN++) {
                    let day = {
                        date: new Date(curDate.getTime()),
                        timestamp: parseInt(curDate.getTime()/1000)
                    };
                    day.classnames = {
                        'other-month': day.date.getMonth() != date.getMonth(),
                        'first-of-month': day.date.getDate() == 1
                    };

                    objWeek.days.push(day);

                    curDate.setDate( curDate.getDate()+1 );
                }

                retval.push(objWeek);
            }

            return retval;
        },

        loadNext() {
            let latestDate = new Date( this.months[this.months.length-1].date.getTime() );
            latestDate.setMonth( latestDate.getMonth() + 1, 1 );

            this.months.push({
                date: latestDate,
                weeks: this.getWeeks(latestDate, this.startDay)
            });
        },

        loadPrevious() {
            this.months.splice(3);

            let firstDate = new Date( this.months[0].date.getTime() );
            firstDate.setMonth( firstDate.getMonth(), 0 );

            this.months.unshift({
                date: firstDate,
                weeks: this.getWeeks(firstDate, this.startDay)
            });
        },

        appendMonth(date) {
            this.months.push({
                date: new Date(date.getTime()),
                weeks: this.getWeeks(date, this.startDay)
            });
        },

        focusMonth(date) {
            let monthStart = new Date(date.getTime());
            monthStart.setHours(0,0,0,0);
            monthStart.setDate(1);

            this.$emit("focusMonth", monthStart);
        },

        reset(date) {
            this.months = [];

            let lastMonth = new Date(date.getTime());
            lastMonth.setMonth(lastMonth.getMonth(), 0);
            this.appendMonth(lastMonth);

            let thisMonth = new Date(date.getTime());
            this.appendMonth(thisMonth);

            let nextMonth = new Date(date.getTime());
            nextMonth.setMonth(nextMonth.getMonth() + 1, 1);
            this.appendMonth(nextMonth);

            this.focusMonth(date);
            this.focusedMonthIndex = 1;
            this.$nextTick(() => {
                // this.$el.querySelector('.phi-calendar > :nth-child(2)').scrollIntoView();
                this.$el.scrollTo(this.$el.offsetWidth + 60, 0);
            });
        }
    },

    mounted() {
        this.reset(this.value);


        // Listen for scroll events
        this.$el.addEventListener('scroll',  event => {

            // Determine the current visible element
            let visibleIndex = Math.floor( (event.target.scrollLeft + event.target.offsetWidth/2 ) / event.target.offsetWidth);
            if (visibleIndex != this.focusedMonthIndex) {
                this.focusedMonthIndex = visibleIndex;
                this.focusMonth(this.months[visibleIndex].weeks[1].days[0].date);

                if (visibleIndex == this.months.length-1) {
                    this.loadNext();
                }
            }

            if (event.target.scrollLeft == 0) {
                this.loadPrevious();

                // this.$el.querySelector('.phi-calendar > :nth-child(2)').scrollIntoView();
                this.$el.scrollTo(this.$el.offsetWidth, 0);
                this.focusedMonthIndex = 1;
            }

        }, false);
    }
}
</script>

<style lang="scss">
.phi-calendar {
    -webkit-scroll-snap-type: mandatory;
    -webkit-scroll-snap-points-x: repeat(100%);

    scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(100%);
    scroll-snap-type: x mandatory;
    scroll-padding: 0;

    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;

    display: flex;

    .phi-calendar-month {
        scroll-snap-align: center;
        -webkit-scroll-snap-coordinate: 50% 50%;

        display: block;
        margin: 0;
        padding: 0;

        width: 100%;
        min-width: 100%;

        table {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;

            thead {
                td {
                    padding: 3px;
                    padding-bottom: 12px;
                    // font-weight: bold;
                    font-size: .8em;
                }
            }

            td {
                width: 14.2%;
                text-align: center;
            }
        }
    }



    .phi-calendar-day {
        position: relative;
        padding: 12px 8px 8px 8px;
        background-color: #fff;

        cursor: pointer;

        .date {
            display: block;

            .month {
                display: none;

                position: absolute;
                top: -2px;
                left: 2px;


                opacity: .2;
                // font-weight: bold;
                font-size: .8em;

            }

            .day {
                text-align: center;

                display: inline-block;
                font-size: 1.2em;
                opacity: .4;
            }
        }

        .tags {
            display: flex;
            justify-content: center;

            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                padding: 0;

                width: 10px;
                height: 10px;

                font-size: 8px;
                background-color:cadetblue;
                border-radius: 50%;
                color: #fff;
            }
        }

        &.selected {
            background-color: rgba(76, 182, 255, .2);
        }

        &.first-of-month {
            border-left: 1px solid #ccc;
            border-right: none;
            border-bottom: none;

            .date {
                .month {
                    display: inline-block;
                }
            }
        }

        &.first {
            .date {
                .month {
                    display: inline-block;
                }
            }
        }

        &.other-month {
            background-color: rgba(0,0,0, .04);
            opacity: .6;
        }

    }
}
</style>