<template>
    <div class="state-calendar">

        <transition name="slide">
            <router-view></router-view>
        </transition>


        <div class="state-calendar-side-panel" :class="{open: sidePanelOpen}">
            <mu-icon-button icon="clear" @click="sidePanelOpen = false"></mu-icon-button>

            <ul class="state-calendar-filters">
                <li
                    v-for="filter in $store.state.calendar.filters"
                    :key="filter.id"
                    :class="{enabled: filter.enabled}"
                >
                    <div class="phi-media">
                        <phi-type-icon class="phi-media-figure" v-if="filter.type" :value="filter.type"></phi-type-icon>
                        <div class="phi-media-body">{{ filter.label }}</div>
                        <div class="phi-media-right">
                            <mu-switch :value="filter.enabled" @change="$store.commit('toggleFilter', filter)"></mu-switch>
                        </div>
                    </div>

                </li>
            </ul>
        </div>


        <phi-page class="page-calendar" fixed :class="{isOpen}">

            <phi-page-top-panel :open="isOpen">
                <div slot="toolbar">
                    <button type="button" @click="closeCalendar()">
                        <mu-icon value="arrow_back"></mu-icon>
                    </button>
                    <h1>{{ $date(focusedMonth, "MMMM Y") }}</h1>
                </div>

                <div class="calendar-container">
                    <div style="height: 3px">
                        <mu-linear-progress v-show="isLoading" color="#ccc" style="height: 3px; opacity: .5; background-color: transparent"></mu-linear-progress>
                    </div>
                    <phi-calendar
                        v-model="currentDay"
                        @input="setDay(currentDay)"
                        @focusMonth="focusMonth($event)"
                    ></phi-calendar>
                </div>
            </phi-page-top-panel>

            <div slot="toolbar" v-if="!isOpen">
                <h1 @click="openCalendar()" >{{ $date(currentDay, "MMMM Y") }}</h1>
                <mu-icon-button icon="today" @click="openCalendar()"></mu-icon-button>
                <mu-icon-button icon="menu" @click="sidePanelOpen = !sidePanelOpen"></mu-icon-button>
            </div>

            <div class="calendar-events">

                <div
                    v-for="week in weeks"
                    :key="week.id"
                    class="calendar-events-week"
                >
                    <div class="week-label">
                        <span>{{ $date(week.startDate, "EEEE d") }}</span>
                        -
                        <span>{{ $date(week.endDate, "EEEE d") }}</span>
                        <span v-if="$date(week.startDate, 'MMMM') != $date(week.endDate, 'MMMM')"> {{ $date(week.endDate, 'MMMM') }}</span>
                    </div>


                    <div
                        v-for="day in week.days"
                        :key="day.id"
                        class="calendar-events-day"
                        :class="{empty: !$store.getters.events(day.date).length}"
                        :datetime="day.date"
                    >
                        <div class="calendar-events-day-label">
                            <div class="day-label-weekday">{{ $date(day.date, "E") }}</div>
                            <div class="day-label-date">{{ $date(day.date, "d") }}</div>
                        </div>

                        <div class="calendar-events-day-list phi-card">
                            <phi-event-li
                                v-for="event in $store.getters.events(day.date)"
                                :key="event.id"
                                :value="event"
                                @click.native="clickEvent(event)"
                            ></phi-event-li>
                        </div>
                    </div>

                </div>


            </div>
        </phi-page>

    </div>
</template>

<script>
import PhiPageTopPanel from '@/components/Phi/Page/TopPanel.vue';
import PhiCalendar from '@/components/Phi/Calendar.vue';
import PhiEventLi from '@/components/Phi/Event/ListItem.vue';
import PhiTypeIcon from '@/components/Phi/Type/Icon.vue';

export default {
    components: {PhiPageTopPanel, PhiCalendar, PhiEventLi, PhiTypeIcon},
    data() {
        return {
            currentDay: new Date(),
            weeks: [],

            focusedMonth: null,
            isOpen: false,
            firstTimeOpening: true,
            debouncer: null,

            sidePanelOpen: false
        }
    },

    computed: {
        isLoading() {
            return this.$store.state.calendar.isLoading;
        },

        scrollBody() {
            return this.$el.querySelector('.page-calendar .phi-page-body');
        },

        eventTypes() {
            let retval = [];
            let seen = {};
            this.$store.getters.allEvents.forEach(event => {
                if (seen[event.post.type.singular] == undefined) {
                    seen[event.post.type.singular] = true;
                    retval.push(event.post.type);
                }
            });

            return retval;
        }
    },

    watch: {
        eventTypes(newValue) {
            newValue.forEach(eventType => {
                this.$store.commit("addEventFilter", {
                    id: eventType.id ? eventType.id : eventType.singular,
                    label: eventType.plural,
                    color: eventType.color,
                    enabled: true,
                    validate(event) {
                        return event.post.type.singular == eventType.singular;
                    },
                    type: eventType
                });
            });
        }
    },

    methods: {
        clickEvent(event) {
            /* Evento de aula virtual */
            if (event.id && event.personId && event.groupId) {
                this.$router.push(`/people/${event.personId}/classroom/${event.groupId}/posts/${event.id}`);
                return;
            }

            /* Evento de comunicado */
            if (typeof event.post != 'undefined' && event.post.thread2) {
                let threadId = event.post.thread2;
                this.$router.push({name: 'event-thread', params:{threadId}});
            }
        },

        openCalendar() {
            this.isOpen = true;

            if (this.firstTimeOpening) {
                setTimeout(() => {
                    this.$el.querySelector('.phi-calendar > :nth-child(2)').scrollIntoView();
                }, 100);
                this.firstTimeOpening = false;
            }
        },

        closeCalendar() {
            this.isOpen = false;
        },

        toggleCalendar() {
            this.isOpen ? this.closeCalendar() : this.openCalendar();
        },

        focusMonth(date) {
            this.focusedMonth = date;

            clearTimeout(this.debouncer);
            this.debouncer = setTimeout(() => {
                let startDate = new Date(date.getTime());
                startDate.setDate(1);
                startDate.setHours(0,0,0,0);

                let endDate = new Date(startDate.getTime());
                endDate.setMonth(endDate.getMonth()+1);

                this.$store.dispatch("fetchEvents", [startDate, endDate]);
            }, 420);
        },

        setDay(date) {
            this.currentDay = date;

            this.weeks = [];

            // add the remainder of the current week
            let startDate = new Date(this.currentDay);
            startDate.setHours(0,0,0,0);


            let endOfWeek = new Date(startDate);
            endOfWeek.setHours(23,59,59,59);
            endOfWeek.setDate(endOfWeek.getDate() + (7 - endOfWeek.getDay()) % 7);


            this.weeks.push( this.fetchWeek(startDate, endOfWeek) );

            // And also add the next week
            this.fetchNext();

            // cleanup UI
            this.closeCalendar();
            this.scrollBody.scrollTo(0, 0);
        },

        fetchWeek(startDate, endDate) {
            this.$store.dispatch("fetchEvents", [startDate, endDate]);

            let newWeek = {
                id: startDate.getTime(),
                startDate,
                endDate,
                days: []
            };

            let loop = new Date(newWeek.startDate);
            while (loop < newWeek.endDate) {
                newWeek.days.push({
                    id: loop.getTime(),
                    date: loop
                });

                loop = new Date(loop);
                loop.setDate(loop.getDate()+1);
            }

            return newWeek;
        },

        fetchNext() {
            if (!this.weeks.length) {
                return;
            }

            let prevWeek = this.weeks[this.weeks.length-1];

            let startDate = new Date(prevWeek.endDate);
            startDate.setDate( startDate.getDate() + 1 );

            let endDate = new Date(startDate);
            endDate.setDate( endDate.getDate() + 6 );

            this.weeks.push( this.fetchWeek(startDate, endDate) );
        }
    },

    mounted() {
        this.setDay(this.currentDay);

        this.scrollBody.addEventListener("scroll", event => {

            /* Set current day according to top visible event */
            let dayElems = this.$el.querySelectorAll('.calendar-events-day');
            for (let k = 0; k < dayElems.length; k++) {
                let dayElem = dayElems[k];
                if ( (dayElem.offsetTop + dayElem.offsetHeight - 60) > event.target.scrollTop) {
                    let dateTime = dayElem.getAttribute("datetime");
                    if (dateTime) {
                        this.currentDay = new Date(dateTime);
                    }
                    break;
                }
            }

            /* Append more days when we reach the bottom */
            let distanceToBottom = event.target.scrollHeight - (event.target.scrollTop + event.target.offsetHeight);
            if (distanceToBottom == 0) {
                this.fetchNext();
            }
        });
    },

    created() {

        //get post types and create feeds for every one of them
        this.$store.state.api.collection(`people/${this.$store.state.user.id}/threads/types`, {hasEvent: 1})
            .fetch()
            .then( types => {
                types.forEach( (typeDef, index) => {
                    
                    this.$store.dispatch("addFeed", {
                        id: 'glo-' + index + '-' + this.$store.state.user.id,
                        enabled: true,
                        params: {"type":typeDef.singular},
                        url: `/people/${this.$store.state.user.id}/calendar/feed`
                    });

                });
            });

        this.$store.dispatch("addFeed", {
            id: this.$store.state.user.id,
            enabled: true,
            url: `/people/${this.$store.state.user.id}/calendar/feed`
        });

        this.$store.dispatch("addFeed", {
            id: 'comm-' + this.$store.state.user.id,
            enabled: true,
            url: `/1/communication/people/${this.$store.state.user.id}/events`
        });
        
        this.$store.dispatch("addFeed", {
            id: 'google-' + this.$store.state.user.id,
            enabled: true,
            url: `v3/calendar/google/${this.$store.state.user.id}/feed`
        });

        this.$store.dispatch("addFeed", {
            id: 'exam-' + this.$store.state.user.id,
            enabled: true,
            url: `v3/people/${this.$store.state.user.id}/academic/exams/feed`,
            params: {"oncreation":0}
        });

        this.$store.dispatch("addFeed", {
            id: 'assi-' + this.$store.state.user.id,
            enabled: true,
            url: `v3/people/${this.$store.state.user.id}/academic/assignments/feed`,
            params: {"oncreation":0}
        });

        this.$store.dispatch("addFeed", {
            id: 'eval-' + this.$store.state.user.id,
            enabled: true,
            url: `v3/people/${this.$store.state.user.id}/academic/evaluations/feed`,
            params: {"oncreation":0}
        });

        this.$store.commit("onBackButton", () => {
            if (this.isOpen) {
                this.closeCalendar();
                return false;
            }

            if (this.sidePanelOpen) {
                this.sidePanelOpen = false;
                return false;
            }
        });
    }
}
</script>

<style lang="scss">
.state-calendar {
    height: 100%;

    .phi-page {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
    }

    #thread {
        z-index: 1;
    }

    .state-calendar-side-panel {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 222;

        padding: 0 12px;
        padding-bottom: 120px;

        background-color: #fff;
        transition: transform .23s;
        transform: translateX(100%);

        overflow: hidden;
        overflow-y: scroll;

        &.open {
            transform: translateX(0);
        }
    }

    .state-calendar-filters {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            border-radius: 4px;
            padding: 8px 16px;
            margin-bottom: 12px;

            opacity: .66;

            &.enabled {
                opacity: 1;
            }
        }

        .phi-media {
            align-items: center;
            padding: 0;

            .phi-media-figure {
                width: 30px;
                height: 30px;
            }

            .mu-switch-track {
                background-color: rgba(0,0,0, .1) !important;
            }


            input[type=checkbox]:checked + .mu-switch-wrapper .mu-switch-thumb {
                background-color: rgba(23, 109, 221, 0.562) !important;
            }
        }
    }
}

.page-calendar {
    &.isOpen {
        .phi-page-toolbar {
            display: none;
        }
    }

    .phi-page-toolbar {
        .phi-page-toolbar-bg {
            background-color: #f2f2f2 !important;
        }
    }


    .calendar-events {
        min-height: 105vh;

        &-week {
            min-height: 53vh; /* Garantizar que si hay dos componentes, siempre sera necesario hacer scroll */

            .week-label {
                font-weight: bold;
                font-size: 12px;
                color: #777;
                padding: 6px 0 12px 0;

                opacity: .4;
            }
        }

        &-day {
            display: flex;
            padding-bottom: 16px;

            &-label {
                width: 42px;
                min-width: 42px;
                text-align: center;
                margin-right: 6px;

                .day-label-weekday {
                    opacity: .6;
                    font-size: 13px;
                }

                .day-label-date {
                    font-size: 1.2em;
                    position: relative;
                    top: -4px;
                }
            }

            &-list {
                flex: 1;
            }


            &.empty {
                display: none;
            }
        }
    }
}
</style>